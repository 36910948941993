<template>
  <div>
    <template v-if="$options.components['advertisement-loading']">
      <advertisement-loading v-if="isLoading" />
    </template>
    <template v-if="isLoading">
      <b-card v-for="i in 3" :key="i" class="mb-3">
        <b-skeleton class="mb-2" animation="wave" width="85%"></b-skeleton>
        <b-skeleton class="mb-2" animation="wave" width="55%"></b-skeleton>
        <b-skeleton class="mb-2" animation="wave" width="70%"></b-skeleton>
        <b-skeleton class="mb-2" animation="wave" width="80%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
      </b-card>
    </template>
    <template
      class="mt-0"
      v-if="$options.components['advertisement-top-banner-text']"
    >
      <advertisement-top-banner-text />
    </template>
    <hr class="margintop-1" />
    <b-row>
      <b-col md="7" class="ml-5 mt-3">
        <b-row>
          <h5>Request Medicine</h5>
        </b-row>
        <b-row>
          <b-col md="5">
            <h6>{{ currentQuote.office.officeName }}</h6>
          </b-col>
          <b-col
            ><b-button class="rounded-circle btn-edit">
              <b-icon
                icon="pencil-fill"
                variant="outline-primary"
              ></b-icon></b-button
          ></b-col>
        </b-row>
        <b-row class="address">
          <b-card-text class="mx-3">
            <b-icon-geo-alt-fill></b-icon-geo-alt-fill>
            {{
              currentQuote
                ? currentQuote.office &&
                  currentQuote.office.address &&
                  currentQuote.office.address.street1
                : ""
            }}
            {{
              currentQuote
                ? currentQuote.office &&
                  currentQuote.office.address &&
                  currentQuote.office.address.city
                : ""
            }},
            {{
              currentQuote
                ? currentQuote.office &&
                  currentQuote.office.address &&
                  currentQuote.office.address.state
                : ""
            }},
            {{
              currentQuote
                ? currentQuote.office &&
                  currentQuote.office.address &&
                  currentQuote.office.address.country
                : ""
            }}
          </b-card-text></b-row
        >
        <hr class="w-50" />
        <b-row>
          <b-col md="5">
            <h6>
              Deliver to {{ userInfo.name.first }} {{ userInfo.name.last }} ,{{
                userInfo.addresses.zip
              }}
            </h6>
          </b-col>
          <b-col
            ><b-button class="rounded-circle btn-edit">
              <b-icon
                icon="pencil-fill"
                variant="outline-primary"
              ></b-icon></b-button
          ></b-col>
        </b-row>
        <b-row class="address">
          <b-card-text class="mx-3">
            <b-icon-geo-alt-fill></b-icon-geo-alt-fill>
            {{
              currentQuote
                ? currentQuote.office &&
                  currentQuote.office.address &&
                  currentQuote.office.address.street1
                : ""
            }}
            {{
              currentQuote
                ? currentQuote.office &&
                  currentQuote.office.address &&
                  currentQuote.office.address.city
                : ""
            }},
            {{
              currentQuote
                ? currentQuote.office &&
                  currentQuote.office.address &&
                  currentQuote.office.address.state
                : ""
            }},
            {{
              currentQuote
                ? currentQuote.office &&
                  currentQuote.office.address &&
                  currentQuote.office.address.country
                : ""
            }}
          </b-card-text></b-row
        >
        <hr class="w-50" />
        <b-row>
          <b-col md="5">
            <h6>Delivery Date</h6>
          </b-col>
          <b-col
            ><b-button class="rounded-circle btn-edit">
              <b-icon
                icon="pencil-fill"
                variant="outline-primary"
              ></b-icon></b-button
          ></b-col>
        </b-row>
        <b-row
          ><b-col class="address">
            <b-input class="w-50" type="date" v-model="startDate"></b-input>
          </b-col>
        </b-row>
        <hr class="w-50" />
      </b-col>
      <b-col md="4">
        <h5>Choose Delivery Slots</h5>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="radios-btn-default"
            button-variant="outline-primary"
            buttons
            stacked
          ></b-form-radio-group>
        </b-form-group>
        <b-row class="margin-top-70"
          ><b-button
            variant="primary"
            class="w-75 text-white"
            @click="createQuote"
          >
            Request Medicine
          </b-button></b-row
        >
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: function () {
    return {
      isLoading: false,
      startDate: "",
      currentQuote: "",
      selected: "",
      options: [
        { text: "8:30 AM - 10:00 AM", value: "9 AM" },
        { text: "10:00 AM - 12:00 PM", value: "11 AM" },
        { text: "12:00 PM - 2:00 PM ", value: "1 PM" },
        { text: "2:00 AM - 6:30 PM", value: "4 PM" },
      ],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo,
      defaultAddress: (state) => state.address.defaultAddress,
    }),
  },
  methods: {
    async fetchQuotableItems() {
      const payload = {
        filters: {
          categoryId: "",
          provider: "61af7469a719df0e2b79c5c9",
          isQuotable: "y",
        },
        offset: 0,
      };
      console.log(payload);
      await this.$store.dispatch("healthCare/listQutableItem", payload);
    },
    async createQuote() {
      const payload = {
        customer: {
          id: "6110bd64b238ab7e62a4be56",
          name: "Devan Devan",
          email: "msdevadeth50@gmail.com",
          phone: "9447394819",
        },
        provider: {
          id: "61af7469a719df0e2b79c5c9",
          name: "Santra Bhusimal",
          email: "",
          phone: "+91 97886 57123",
        },
        items: [
          {
            item: {
              IsQuotable: true,
              IsSellable: "true",
              Price: "35",
              Address: {
                Lat: 22.6636967,
                Lng: 87.74680710000001,
                City: "Ghatal",
                State: "West Bengal",
                Country: "India",
                Address: "",
                location: [87.74680710000001, 22.6636967],
              },
              Country: "India",
              State: "West Bengal",
              City: "Ghatal",
              Lat: 22.6636967,
              Lng: 87.74680710000001,
              "Tax Code": "HSN_0910 ",
              Quantity: "100gm",
              sellerContact: "+91 97886 57123",
              catalogId: "606717beae5ecc0e071a0fd0",
              provider: "61af7469a719df0e2b79c5c9",
              description_summary: "Pure Organic Powder",
              Type: "PRODUCT",
              productType: "NORMAL",
              tenant: "601a8bf396821b0a2b28a95e",
              catalog: {
                name: "Powder Spices",
                externalAttributes: [
                  {
                    attributeName: "Name",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Name",
                      bn: "নাম",
                      te: "పేరు",
                      hi: "नाम",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: true,
                    isComparable: false,
                  },
                  {
                    attributeName: "Price",
                    attributeValueType: "Number",
                    attributeLabel: {
                      en: "Price",
                      bn: "দাম",
                      te: "ధర",
                      hi: "दाम",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: true,
                    isComparable: false,
                  },
                  {
                    attributeName: "Brand",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Brand",
                      te: "బ్రాండ్",
                      bn: "ব্র্যান্ড",
                      hi: "ब्रांड",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Description",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Description",
                      bn: "বর্ণনা",
                      te: "వివరణ",
                      hi: "विवरण",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: true,
                    isComparable: false,
                  },
                  {
                    attributeName: "Address",
                    attributeValueType: "Object",
                    attributeLabel: null,
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Country",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Country",
                      bn: "দেশ",
                      te: "దేశం",
                      hi: "देश",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "State",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "State",
                      bn: "রাজ্য",
                      te: "రాష్ట్రం",
                      hi: "राज्य",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "City",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "City",
                      bn: "শহর",
                      te: "నగరం",
                      hi: "शहर",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Area",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Area",
                      bn: "এলাকা ",
                      te: "ప్రాంతం",
                      hi: "इलाका",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Lat",
                    attributeValueType: "Number",
                    attributeLabel: {
                      en: "Lat",
                      hi: "अक्षां",
                      bn: "ল্যাট",
                      te: "లాట్",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Lng",
                    attributeValueType: "Number",
                    attributeLabel: {
                      en: "Lng",
                      hi: "एलएनजी",
                      bn: "এলএনজি",
                      te: "లాన్గ్",
                    },
                    visibility: {
                      onCreate: false,
                      onUpdate: false,
                      onShow: false,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Market",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Market",
                      hi: "बाजार",
                      te: "సంత",
                      bn: "বাজার",
                    },
                    visibility: {
                      onCreate: false,
                      onUpdate: false,
                      onShow: false,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Category",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Category",
                      hi: "श्रेणी",
                      bn: "বিভাগ",
                      te: "వర్గం",
                    },
                    visibility: {
                      onCreate: false,
                      onUpdate: false,
                      onShow: false,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Availability",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Availability",
                      bn: "উপস্থিতি",
                      hi: "उपलब्धता",
                      te: "లభ్యత",
                    },
                    visibility: {
                      onCreate: false,
                      onUpdate: false,
                      onShow: false,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Tax Code",
                    attributeValueType: "String",
                    attributeLabel: {
                      bn: "ট্যাক্স কোড",
                      en: "Tax Code",
                      hi: "टैक्स कोड",
                      te: "పన్ను సంకేతబాష",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Inventory",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Inventory",
                      bn: "ইনভেন্টরি",
                      te: "ఇన్వెంటరీ",
                      hi: "इन्वेंटरी",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "IsSellable",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "IsSellable",
                      bn: "বিক্রয়যোগ্য",
                      te: "అమ్మవచ్చు",
                      hi: "बिक्री योग्य है",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: true,
                    isComparable: false,
                  },
                  {
                    attributeName: "Quantity",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Quantity",
                      bn: "পরিমাণ",
                      te: "పరిమాణం",
                      hi: "मात्रा",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Pack Of",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Pack Of",
                      bn: "প্যাক অফ ",
                      hi: "इसका पैक",
                      te: "ప్యాక్ ఆఫ్ ",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Container Type",
                    attributeValueType: "String",
                    attributeLabel: {
                      bn: "ধারক প্রকার",
                      en: "Container Type",
                      hi: "कंटेनर प्रकार",
                      te: "కంటైనర్ రకం",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Country_Of_Origin",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Country",
                      bn: "দেশ",
                      te: "దేశం",
                      hi: "देश",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Discount",
                    attributeValueType: "Number",
                    attributeLabel: {
                      bn: "ছাড়(%)",
                      en: "Discount",
                      hi: "छूट",
                      te: "డిస్కౌంట్ ",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "Deal Price",
                    attributeValueType: "String",
                    attributeLabel: {
                      bn: "নির্ধারিত দাম",
                      en: "Deal Price",
                      hi: "डील प्राइस",
                      te: "డీల్ ధర",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "sellerContact",
                    attributeValueType: "String",
                    attributeLabel: {
                      en: "Seller Contact",
                      hi: "विक्रेता संपर्क",
                      bn: "বিক্রেতার যোগাযোগ",
                      te: "విక్రేత పరిచయం",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                  {
                    attributeName: "IsQuotable",
                    attributeValueType: "Boolean",
                    attributeLabel: {
                      en: "IsQuotable",
                      bn: "উদ্ধৃতযোগ্য",
                      te: "కోటబుల్",
                      hi: "उद्धरण योग्य है",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: true,
                    isComparable: false,
                  },
                  {
                    attributeName: "StockCount",
                    attributeValueType: "Number",
                    attributeLabel: {
                      en: "StockCount",
                      bn: "স্টক কাউন্ট",
                      te: "స్టాక్ కౌంట్ ",
                      hi: "स्टॉककाउंट",
                    },
                    visibility: {
                      onCreate: true,
                      onUpdate: true,
                      onShow: true,
                    },
                    isRequired: false,
                    isComparable: false,
                  },
                ],
                orderAttributes: [],
              },
              providerName: "Santra Bhusimal",
              isOffline: false,
              variations: null,
              Category: "Spices",
              Market: "Grocery",
              slug: "santra-bhusimal-pure-organic-powder",
              integer_attributes: [],
              string_attributes: [
                {
                  attribute_name: "Name",
                  attribute_value: "Jeera Powder",
                  attribute_name_en: "Name",
                },
                {
                  attribute_name: "Description",
                  attribute_value: "Pure Organic Powder",
                  attribute_name_en: "Description",
                },
              ],
              product_summary: {
                images: {
                  icon: "https://nipigedev-storage.s3.amazonaws.com/images/catalog/knygusoxrs9k8",
                  smallImage:
                    "https://nipigedev-storage.s3.amazonaws.com/images/catalog/knygu1nu6oxsh",
                  bigImage:
                    "https://nipigedev-storage.s3.amazonaws.com/images/catalog/knygu727ek68c",
                  alternateImages: [],
                },
              },
              createdAt: "2022-01-19T05:32:53.576Z",
              updatedAt: "2022-01-19T05:32:53.576Z",
              isDeleted: false,
              avgRating: 0,
              totalReviews: 0,
              itemIndex: 1757,
              _id: "61e7a285b33e6ec0f173d850",
            },
            custom: {
              productName: "Jeera Powder",
              requestedPrice: "30",
              description: "Pure Organic Powder",
              quantity: 6,
            },
            isCancelled: false,
          },
        ],
        deliveryAddress: {
          location: {
            coordinates: [87.74680710000001, 22.6636967],
            type: "Point",
          },
          contacts: [
            {
              _id: "61c17e33b6d190a4faf4971c",
              value: "9778787878",
              contactType: "PRIMARY_PHONE",
            },
            {
              _id: "61c17e33b6d1909478f4971b",
              value: "msdevadeth50@gmail.com",
              contactType: "PRIMARY_EMAIL",
            },
          ],
          _id: "61c17e33b6d1906893f4971a",
          city: "Ghatal",
          country: "India",
          houseNumber: "Ghatal",
          state: "West Bengal",
          street1: "street test",
          street2: "neat test",
          zip: "721212",
          category: "Home",
          fullName: "test",
        },
        status: "Requested",
        tenant: "601a8bf396821b0a2b28a95e",
        totalRequestedAmount: 180,
        requestedDeliveryDate: "2022-04-26T01:00:00.000Z",
      };
      await this.$store.dispatch("healthCare/createQuote", payload);
    },
  },
  mounted() {
    this.currentQuote = this.$route.params.storeData;
    this.fetchQuotableItems();
  },
};
</script>
<style>
.address {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #9e9e9e;
}
.time-btn {
  width: 175px;
  height: 42px;
  border: 1px solid black;
}
.time-btn:hover {
  border: 1px solid #2a8750;
  color: #2a8750;
}
.btn-edit {
  width: 32px;
  height: 25px;
}

.btn .b-icon.bi,
.nav-link .b-icon.bi,
.dropdown-toggle .b-icon.bi,
.dropdown-item .b-icon.bi,
.input-group-text .b-icon.bi {
  font-size: 80% !important;
  vertical-align: text-top !important;
  margin-left: -2px !important;
  margin-top: -1.5px !important;
}
</style>
